import { createReducer } from "@reduxjs/toolkit";
import {
  ENTITIES_ERROR,
  ENTITIES_LIST,
  ENTITIES_LOADING,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const entitiesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ENTITIES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(ENTITIES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(ENTITIES_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default entitiesReducer;
