import grey from "@mui/material/colors/grey";

const getDesignTokens = (mode, isRTL) => ({
  typography: {
    allVariants: {
      fontFamily: isRTL === "rtl" ? "LamaSans" : "Almarai",
      textTransform: "none",
      " --bs-font-sans-serif":
        "system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
      "--bs-font-monospace":
        "SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace",
      "--bs-body-font-family": "Almarai,sans-serif",
    },
    h1: {
      fontSize: 32.44,
    },
    h2: {
      fontSize: 28.83,
    },
    h3: {
      fontSize: 25.63,
    },
    h4: {
      fontSize: 22.78,
    },
    h5: {
      fontSize: 20.25,
    },
    h6: {
      fontSize: 18,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14.22,
    },
    subtitle1: {
      fontSize: 12.64,
    },
    subtitle2: {
      fontSize: 11.24,
    },
    caption: {
      fontSize: 9.99,
    },
    button: {
      fontWeight: 700,
    },
  },
  palette: {
    mode,
    ...{
      primary: {
        main: "#00CCCC",
        background: "#00CCCC",
        default: "#00CCCC",
        message: "#00CCCC",
        white: "#fff",
        blackX: "#fff",
        hover: "#857FB3",
        companyBack: `#F6F8FA`,
        contrastText: "#fff",
        header: "#0E0F12",
        create: "#039F9F",
        view: "#FFFFFF1F",
        appForm: "#F1F1F9",
        buttonBlack: "#232323",
      },
      secondary: {
        main: "#FF9999",
        background: "#00CCCC",
        contrastText: "#fff",
        default: "#FF9999",
        green: "#3B8A8A",
        olive: "#2A8282",
        cart: "#F6F6F6",
        feature: "#FEF3F3",
        black: "#0E0F12",
        grey: "#D9D9D9",
        blackx: "#060608",
      },
      divider: "#000000",
      text: {
        primary: "#000000",
        secondary: grey[800],
        border: grey[200],
        mainGrey: "#FCFCFC",
        white: "#fff",
        darkGreen: "#003939",
        green: "#006464",
        disable: "#706F6F",
        timer: "#FA9090",
      },
      background: {
        default: "#fff",
        paper: "#fff",
        main: "#fff",
        message: "#4849A1",
        postFoot: "#F2F3F9",
        grey: "#FCFCFC",
        secondary: "#010C19",
        black: "#000",
      },
      dots: {
        default: "#CFCFCF",
        active: "#FEC600",
      },
      main_baack: "#fff",
      appBar: {
        background: "#fff",
      },
      buttonWhite: `#fff`,
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          height: 0,
        },
      },
    },
    MuiTextareaAutosize: {
      styleOverrides: { root: { background: "#202225" } },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: "Almarai",
          ...(mode === "light"
            ? {
                scrollbarColor: "#6b6b6b #202225",
                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  backgroundColor: "#fff",
                  width: 8,
                },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                  borderRadius: 8,
                  backgroundColor: "#4849A1",
                  minHeight: 24,
                  border: "3px solid #4849A1",
                },
                "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                  {
                    backgroundColor: "#4849A1",
                  },
                "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                  {
                    backgroundColor: "#4849A1",
                  },
                "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                  {
                    backgroundColor: "#4849A1",
                  },
                "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                  backgroundColor: "transparent",
                },
              }
            : {
                scrollbarColor: "#6b6b6b #202225",
                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  backgroundColor: "#202225",
                  width: 10,
                },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                  borderRadius: 8,
                  backgroundColor: "#6b6b6b",
                  minHeight: 24,
                  border: "3px solid #202225",
                },
                "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                  {
                    backgroundColor: "#959595",
                  },
                "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                  {
                    backgroundColor: "#959595",
                  },
                "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                  {
                    backgroundColor: "#959595",
                  },
                "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                  backgroundColor: "#202225",
                },
              }),
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid",
          borderColor: mode === "light" ? grey[200] : grey[800],
          fontFamily: "Almarai",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid",
          borderColor: mode === "light" ? grey[200] : grey[800],
          fontFamily: "Almarai",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
  },
});

export default getDesignTokens;
