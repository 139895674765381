import { BrowserRouter } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import Router from "./Router";
// import { useEffect } from "react";
// import { IsAuthenticatedAction } from "./store/actions/auth";
// import { useDispatch } from "react-redux";

export default function Splash({ isRTL }) {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch && IsAuthenticatedAction()(dispatch);
  // }, [dispatch]);

  return (
    <BrowserRouter>
      <Router isRTL={isRTL} />
    </BrowserRouter>
  );
}

export const AppLoader = () => {
  return (
    <Box
      sx={{ height: "100vh" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      justifyItems="center"
    >
      <CircularProgress height={64} />
    </Box>
  );
};
