import { createReducer } from "@reduxjs/toolkit";
import {
  PRIVACYPOLICY_ERROR,
  PRIVACYPOLICY_LIST,
  PRIVACYPOLICY_LOADING,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const PrivacyPolicyReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PRIVACYPOLICY_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(PRIVACYPOLICY_ERROR, (state, { payload }) => {
      state.error = payload;
    })
    .addCase(PRIVACYPOLICY_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default PrivacyPolicyReducer;
