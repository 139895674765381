import { createReducer } from "@reduxjs/toolkit";
import {
  CATEGORYAPP_ERROR,
  CATEGORYAPP_LOADING,
  CATEGORYAPP_LIST,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const categoryAppReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CATEGORYAPP_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(CATEGORYAPP_ERROR, (state, { payload }) => {
      state.error = payload;
    })
    .addCase(CATEGORYAPP_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default categoryAppReducer;
