import { createReducer } from "@reduxjs/toolkit";
import {
  INDUSTRY_LOADING,
  INDUSTRY_ERROR,
  INDUSTRY_LIST,
  ALL_INDUSTRY_LOADING,
  ALL_INDUSTRY_ERROR,
  ALL_INDUSTRY_LIST,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
  allData: [],
};

const industryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(INDUSTRY_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(INDUSTRY_ERROR, (state, { payload }) => {
      state.error = payload;
    })
    .addCase(INDUSTRY_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })

    .addCase(ALL_INDUSTRY_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(ALL_INDUSTRY_ERROR, (state, { payload }) => {
      state.error = payload;
    })
    .addCase(ALL_INDUSTRY_LIST, (state, { payload }) => {
      state.allData = payload;
      state.loading = false;
    });
});

export default industryReducer;
