import { createReducer } from "@reduxjs/toolkit";
import { FAQ_ERROR, FAQ_LOADING, FAQ_LIST } from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const FAQReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(FAQ_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(FAQ_ERROR, (state, { payload }) => {
      state.error = payload;
    })
    .addCase(FAQ_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default FAQReducer;
