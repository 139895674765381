export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const APPS_LOADING = "APPS_LOADING";
export const APPS_ERROR = "APPS_ERROR";
export const APPS_LIST = "APPS_LIST";

export const ABOUT_LOADING = "ABOUT_LOADING";
export const ABOUT_ERROR = "ABOUT_ERROR";
export const ABOUT_LIST = "ABOUT_LIST";

export const SEO_LOADING = "SEO_LOADING";
export const SEO_ERROR = "SEO_ERROR";
export const SEO_LIST = "SEO_LIST";

export const PAGES_LOADING = "PAGES_LOADING";
export const PAGES_ERROR = "PAGES_ERROR";
export const PAGES_LIST = "PAGES_LIST";

export const STORE_LOADING = "STORE_LOADING";
export const STORE_ERROR = "STORE_ERROR";
export const STORE_LIST = "STORE_LIST";

export const PACKAGE_LOADING = "PACKAGE_LOADING";
export const PACKAGE_ERROR = "PACKAGE_ERROR";
export const PACKAGE_LIST = "PACKAGE_LIST";

export const INDUSTRY_LOADING = "INDUSTRY_LOADING";
export const INDUSTRY_ERROR = "INDUSTRY_ERROR";
export const INDUSTRY_LIST = "INDUSTRY_LIST";
export const ALL_INDUSTRY_LOADING = "ALL_INDUSTRY_LOADING";
export const ALL_INDUSTRY_ERROR = "ALL_INDUSTRY_ERROR";
export const ALL_INDUSTRY_LIST = "ALL_INDUSTRY_LIST";

export const CATEGORY_LOADING = "CATEGORY_LOADING";
export const CATEGORY_ERROR = "CATEGORY_ERROR";
export const CATEGORY_LIST = "CATEGORY_LIST";

export const CATEGORYAPPS_LOADING = "CATEGORY_LOADING";
export const CATEGORYAPPS_ERROR = "CATEGORY_ERROR";
export const CATEGORYAPPS_LIST = "CATEGORY_LIST";

export const CATEGORYAPP_LOADING = "CATEGORYAPP_LOADING";
export const CATEGORYAPP_ERROR = "CATEGORYAPP_ERROR";
export const CATEGORYAPP_LIST = "CATEGORYAPP_LIST";

export const APP_LOADING = "APP_LOADING";
export const APP_ERROR = "APP_ERROR";
export const APP_LIST = "APP_LIST";

export const FAQ_LOADING = "FAQ_LOADING";
export const FAQ_ERROR = "FAQ_ERROR";
export const FAQ_LIST = "FAQ_LIST";

export const PRIVACYPOLICY_LOADING = "PRIVACYPOLICY_LOADING";
export const PRIVACYPOLICY_ERROR = "PRIVACYPOLICY_ERROR";
export const PRIVACYPOLICY_LIST = "PRIVACYPOLICY_LIST";

export const TERMS_LOADING = "TERMS_LOADING";
export const TERMS_ERROR = "TERMS_ERROR";
export const TERMS_LIST = "TERMS_LIST";

// Industries
export const INDUSTRIES_LOADING = "INDUSTRIES_LOADING";
export const INDUSTRIES_ERROR = "INDUSTRIES_ERROR";
export const INDUSTRIES_LIST = "INDUSTRIES_LIST";

// packages
export const PACKAGES_LOADING = "PACKAGES_LOADING";
export const PACKAGES_ERROR = "PACKAGES_ERROR";
export const PACKAGES_LIST = "PACKAGES_LIST";

//entities
export const ENTITIES_ERROR = "ENTITIES_ERROR";
export const ENTITIES_LIST = "ENTITIES_LIST";
export const ENTITIES_LOADING = "ENTITIES_LOADING";

//Business require
export const BUSINESS_REQUIRE_LOADING = "BUSINESS_REQUIRE_LOADING";
export const BUSINESS_REQUIRE_ERROR = "BUSINESS_REQUIRE_ERROR";
export const BUSINESS_REQUIRE_LIST = "BUSINESS_REQUIRE_LIST";

//Theme
export const THEME_ERROR = "THEME_ERROR";
export const ADD_THEME = "ADD_THEME";
export const THEME_LOADING = "THEME_LOADING";

// order
export const ORDER_LOADING = "ORDER_LOADING";
export const ORDER_ERROR = "ORDER_ERROR";
export const PUT_ORDER = "PUT_ORDER";

// Objectives
export const OBJECTIVES_LOADING = "OBJECTIVES_LOADING";
export const OBJECTIVES_ERROR = "OBJECTIVES_ERROR";
export const OBJECTIVES_LIST = "OBJECTIVES_LIST";

//Template Apps
export const TEMP_APPS_LOADING = "TEMP_APPS_LOADING";
export const TEMP_APPS_ERROR = "TEMP_APPS_ERROR";
export const TEMP_APPS_LIST = "TEMP_APPS_LIST";
export const TEMP_APP_SHOW = "TEMP_APP_SHOW";
export const COPY_APP = "COPY_APP";

// Palettes
export const PALETTES_LOADING = "PALETTES_LOADING";
export const PALETTES_ERROR = "PALETTES_ERROR";
export const PALETTES_LIST = "PALETTES_LIST";
