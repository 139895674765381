import { createReducer } from "@reduxjs/toolkit";
import {
  PACKAGES_LOADING,
  PACKAGES_ERROR,
  PACKAGES_LIST,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const packagesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PACKAGES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(PACKAGES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(PACKAGES_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default packagesReducer;
